.productFilterSec {
    padding: 15px;
    background:  #efefef;
    position: sticky;
    top: 130px;

}


.rangeNumber span {
    display: inline-block;
    background: #f7f7f7;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.productsFilterItem h4 {
    margin-bottom: 10px;
    text-transform: capitalize;
    color: #454545;
    font-size: 16px;
}

.rangeNumber {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.productsFilterItem .form-check {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.productsFilterItem .form-check .form-check-input {
    margin-top: 0;
}
.productsFilterItem .form-check .form-check-input:checked{
    background-color: #06000c;
    border: #06000c;
    box-shadow: 0 0 0 0.25rem #06000c2e;
}
.productsFilterItem .form-check .form-check-label {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 17px;
    color: #878787;
}

.pagination.paginationSec {
    border: 0;
}

.pagination.paginationSec li a {
    background-color: transparent;
    border: 0;
    color: #7a7a7a;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
}

.pagination.paginationSec .page-item .page-link svg {
    font-weight: bold;
    font-size: 20px;
}
.pagination.paginationSec .page-item:nth-child(2) .page-link {
    background-color: #06000c;
    color: #fff;
    border-radius: 4px;

}